<template>
  <div class="tabs-container w-full mt-3">
    <PageHeader
      :showButton="false"
      :heading="$t('xpbx.settings.ivrs_detail.heading')"
    />
    <TabView :activeIndex="activeTabIndex" @tab-change="onTabChange">
      <TabPanel v-for="(tab, index) in tabs" :key="index" :header="tab?.label">
        <div>
          <FlowGeneral :data="{}" v-if="tab.key === 'general'" />
          <FlowVersions :data="{}" v-if="tab.key === 'versions'" />
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script>
import { computed, inject, onMounted, ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import PageHeader from "@/modules/xpbx/components/home/UI/PageHeader.vue";
import FlowGeneral from "@/modules/xpbx/pages/settings/flow-detail/components/FlowGeneral.vue";
import FlowVersions from "@/modules/xpbx/pages/settings/flow-detail/components/FlowVersions.vue";
import { useRouter, useRoute } from "vue-router";

export default {
  name: "IvrDetail",

  components: {
    TabView,
    TabPanel,
    PageHeader,
    FlowGeneral,
    FlowVersions,
  },

  setup() {
    const t = inject("t");
    const route = useRoute();
    const router = useRouter();
    const activeTabIndex = ref(0);

    const tabs = computed(() => {
      return [
        { key: "general", label: t("xpbx.settings.ivrs_detail.tabs.general") },
        {
          key: "versions",
          label: t("xpbx.settings.ivrs_detail.tabs.ivr_versions"),
        },
      ];
    });

    const onTabChange = (e) => {
      const query = route?.query;
      activeTabIndex.value = e.index;

      if (e.index === 1) {
        router.push({ query: { ...query, tab: "version" } });
      } else {
        router.push({ query: {} });
      }
    };

    onMounted(() => {
      const query = route?.query;

      if (query?.tab === "version") activeTabIndex.value = 1;
    });

    return {
      tabs,
      activeTabIndex,
      onTabChange,
    };
  },
};
</script>